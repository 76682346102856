import { ILayoutStore, IState, useLayoutStore, useMainStore } from "@/settings/zustand"
import { getRoute, login, mainMenu, moduleExists, template } from "@/libs/layouts"
import { LayoutIcon } from "@/libs/useful"
import { Widgets, __, isCapability } from "@/libs/utilities"
import { Fragment, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { Link, NavLink } from "react-router-dom"
import LayoutHeaderHeader from "./LayoutHeaderHeader"
import UserMobile from "./login/UserMobile"
import useScrollDirection from "src/modules/pe-basic-module/hooks/useScrollDirection"
import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { Button, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core"
import { isRouteOpen } from "../utils/isRouteOpen"
import { useSwipe } from "@/modules/pe-basic-module/hooks/useSwipe"

const LayoutHeaderMobile = (): JSX.Element => { 
    const [swipeDirection, setSwipeDirection] = useState<"up" | "down" | "">("")
    const up = ():void => {
        console.log("up")
        setSwipeDirection("up")
    } 
    const down = ():void => {
        console.log("down")
        setSwipeDirection("down")
    } 
    const scrollDirection = useScrollDirection() 
    const swipe = useSwipe({ up, down })
    const user = useMainStore(( state: IState ) => state.user)  
    const fixMobileTop:boolean = useLayoutStore((state:ILayoutStore) => state.fixMobileTop)

    const mainMenuItems: any[] = mainMenu()
        .filter( e => !e.is_hidden  && moduleExists( e.module ) )
        .filter( e => !isCapability(e.capability, user) )
        .filter( e => !( e.islogged === true && !user.id ) ) 
        .filter( (e, i) => i < 3 )
        .map( ( e, i ) => <MenuItem item={e} i={i} key={ "" + i + e.id }/> )
    return  <>
        <div 
            className={`
                pe-layout-header-mobyle 
                iconic  
                ${ 
                    !fixMobileTop && 
                    (scrollDirection === "down" || scrollDirection === "none" || swipeDirection === "down") 
                        ? 
                        "closing" 
                        : 
                        "open" 
                    }
                `
            }>
            <LayoutHeaderHeader />
            <LayoutUserPanel/>
        </div> 
        <div 
            className="" 
            style={{ 
                minHeight: !fixMobileTop && 
                    (scrollDirection !== "down" && scrollDirection !== "none" && swipeDirection !== "down") 
                        ? 
                        75 
                        : 
                        0,
                transition: "all 500ms ease-out"
            }}
        />
        <div 
            className={`
                pe-layout-header-menu-mobyle
                d-flex 
                ${ !fixMobileTop && (scrollDirection !== "down" && scrollDirection !== "none" && swipeDirection !== "down") 
                    ? 
                    "closing" 
                    : 
                    "open" 
                }                
            `}>
            <Widgets areaName="before-header-menu" data={{}} /> 
            { mainMenuItems }
            <Widgets areaName="after-header-menu" data={{}} /> 
            <MenuPopover />
        </div>
    </>
} 
export default LayoutHeaderMobile


const LayoutUserPanel = ( ) => {
    const user = useMainStore(( state: IState ) => state.user)
    const location = useLocation()
    return user?.id 
        ? 
        <UserMobile />
        : 
        <Link 
            state={{ pathname: location.pathname }}
            className={`icon-unlogined mx-3 ${template().header_menu}`} 
            to={login()} 
        > 
            <i className="fas fa-sign-in-alt mr-2"></i>
            {__("Enter")} 
        </Link>
}


interface IMenuItemElement {
    item: IMenuItem
    i: number
}
const MenuItem = ({item, i}: IMenuItemElement) => { 
    const landId:ID = useMainStore( ( state: IState ) => state.landId )  
    const event_types: string[] =  useMainStore( ( state: IState ) => state.event_types ) || []
    const [isOpen, setIsOpen] = useState<boolean>(isRouteOpen(item, landId, event_types))
    useEffect(() => {
        setIsOpen( isRouteOpen(item, landId, event_types) )
    }, [ item, landId, event_types ])
    const onRoute = ( ) => {

    }   
    if (item.children && item.children.length > 0) {  
        return <Fragment> 
                <NavLink 
                    onClick={ () => onRoute() }
                    to={ { pathname: `/${ getRoute(item) }`  } }
                    key={ i }
                    className={`pt-1 mobyle-main-menu-link ${ isOpen ? "opened" : "closed" }`}  
                >
                    <div className={ `header-menu-element ${ template().header_menu }` }>
                        <LayoutIcon
                            src={ item.icon }
                            className="header-menu-icon"
                            style={{ width: 40, height: 40 }}
                        />
                    </div>
                    <div className="pe-mobyle-under-menu-arrow" />
                    <div className="pe-mobyle-under-menu-label">
                        {__(item.title)}
                    </div>
                </NavLink> 
            </Fragment>
    } 
    return (
        <NavLink 
            //onClick={ () => onRoute( item ) }
            to={ { pathname: `/${ getRoute(item) }` } }
            key={ i }
            className={` mobyle-main-menu-link ${ isOpen ? "opened" : "closed" }`}
        >
            <div className={ `header-menu-element ${ template().header_menu }` }>
                <LayoutIcon
                    src={ item.icon }
                    className="header-menu-icon"
                    style={{ width: 40, height: 40 }}
                /> 
            </div>
            <div className="pe-mobyle-under-menu-label">
                {__(item.title)}
            </div>
        </NavLink>
    )
} 

const MenuPopover = (props: any) : JSX.Element | false => {
    const user = useMainStore(( state: IState ) => state.user)    
    const mainMenuItems: any[] = mainMenu()
        .filter( e => !e.is_hidden  && moduleExists( e.module ) )        
        .filter( e => !isCapability(e.capability, user) )
        .filter( e => !( e.islogged === true && !user.id ) ) 
        .filter((e, i) => i >= 3 )
        .map(( e, i ) => <MenuItem item={e} i={i} />) 
    return !!mainMenuItems.length &&
        <Popover
            position={Position.TOP_RIGHT}
            interactionKind={PopoverInteractionKind.CLICK}
            modifiers={{
                arrow: { enabled: false },
                flip: { enabled: true },
                preventOverflow: { enabled: false },
            }}
            content={<div className="pe-mobile-popover-menu">
                {mainMenuItems}
            </div>}
        >
            <Button
                className=" pt-1 mobyle-main-menu-link m-0 px-0"
                minimal
            >
                <div className={ `header-menu-element ${ template().header_menu }` }>
                    <LayoutIcon
                        src={ "fas fa-ellipsis-v" }
                        className="header-menu-icon"
                        style={{ width: 20, height: 20 }}
                    /> 
                </div>
                <div className="pe-mobyle-under-menu-label">
                    {__("Also")}
                </div>
            </Button>
        </Popover>
}