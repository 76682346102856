import { COLOR_TYPE, ID } from "src/libs/interfaces/layouts"

export default interface IScalarForm {
    type? : SCALAR_TYPES | string | null
    title?: string | React.ReactElement | null
    field?: string
    prefix?: string | React.ReactElement | null
    postfix?: string | React.ReactElement | null
    description?: string | React.ReactElement | null
    commentary?: string | React.ReactElement | null
    pseudoTitle?: string  
    tags?: string[]
    visibleValue?: string | null
    value: string | any 
    values?: any[] | null
    default?: string | any | null
    className?: string | null
    style?: any
    vertical?: VerticalType
    onChange? : ( value: any, file: any, name: string ) => void
    on?: ( value: any, file: any, name: string ) => void
    onClear?: ( field: string ) => void
    editable? : boolean
    notClear?: boolean
    autoFocus?: boolean
    isFileNameHide?: boolean    // Media
    subMenus?: any
    onSubMenu?: () => void
    width?: number | string     // Media
    height?: number | string    // Media
    tab?: string                // Media
    id?: ID,
    ID?: ID
    accept?: string | undefined,// Media
    isUploadHide?: boolean,     // Media
    isURLHide?: boolean         // Media
    hideLib?: boolean           // Media
    limitSize?: number          // Media
    multiple?: boolean          // Select
    min?: number                // Slider
    max?: number                // Slider, Upload (per kb)
    component?: string          // External
    paging?: string             // External
    landId?: ID                 // External
    origin?: any
    stepSize? : number          // Slider
    labelStepSize? : number     // Slider
    inputStyle?: any
    filter?: any,
    sort?: SortField,
    placeholder?: string
    visualization?: string
    enabledLabel? : string      // Boolean
    disabledLabel? : string     // Boolean
    colorType?: COLOR_TYPE      // Color
    onlyPlainColor?: boolean    // Color
    onlyGradientColor?: boolean // Color 
    isMulti?: boolean           // User 
    onlyThisLands?: boolean     // User 
}

export type FilterField = {
    
}
export type SortField = {
    field: string,
    order: "ASC" | "DESC"
}

export enum VerticalType {
    HORIZONTAL,
    VERTICAL,
}
export interface IScalarObject {
    enabledForm? :  React.ReactElement
    desabledForm? : React.ReactElement
}

export interface ISingleField {
    _id: ID,
    title: string,
    value?: any
    description?: string
    commentary?: string
}

export enum SCALAR_TYPES {
    STRING="string",
    DATA_TYPE="data_type",
    COMPONENT="component",
    NUMBER="number",
    INT="int",
    SLIDER="slider",
    UPLOAD="upload",
    ADDRESS="address",
    SELECT="select",
    CHECKBOX="checkbox",
    IMAGE_CHECKBOX="image_checkbox",
    RADIO="radio",
    IMAGE_RADIO="image_radio",
    TEXT="text",
    HTML="html",
    ID="id",
    DATE="date",
    GEO="geo",
    BOOLEAN="boolean",
    COLOR="color",
    RGB="rgb",
    EXTERNAL="external",
    EXTERNAL_ARRAY="external_array",
    ARRAY="array",
    JSON="json",
    MEDIA="media",
    GALLERY="gallery",
    EMAIL="email",
    URL="url",
    PHONE="phone",
    PASSWORD="password",
    TAGS="tags",
    LINK="link",
    RELOADITABLE="reloaditabled",
    ICON="icon",
    TITLE="title",
    RUTUBE_ID="rutube_id",
    VK_VIDEO_ID="vk_video_id",
    DZEN_VIDEO_ID="dzen_video_id",
    YOUTUBE_ID="youtube_id",
    YOUTUBE_LIST="youtube_list",
    CHECKBOX_GROUP="checkbox_group",
    RUSSIAN_REGIONS="RussianRegions", 
    POST_STATUS="post_status", 
    TIME_DURATION="time_duration", 
    SPEEDOMETER="speedometer", 
    PERCENTAGE="percentage", 
    DEPTH="depth", 
    USER="user",
}