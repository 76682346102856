import { IUser } from "@/settings/zustand"
import FieldInput, { SCALAR_TYPES } from "../scalars"
import { useEffect, useState } from "react"
import { __ } from "../utilities"
import { Collapse } from "@blueprintjs/core"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SEARCH_USERS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { LoaderMinimal } from "./Loading"
import { ID, Role } from "../interfaces/layouts"
import { responsePathAsArray } from "graphql"

/* Protopia Ecosystem component */
export interface IUserSearchEngineProps {
    user?: IUser | null
    onSelect: (user:IUser) => void
    landId?: ID
    minWidth? : number
    onlyThisLands? : boolean
    isMulti? : boolean
    notClear? : boolean
    role?: Role[]
}
const UserSearchEngine = (props: IUserSearchEngineProps): JSX.Element => { 
    const [name, setName] = useState<string>(props.user?.display_name || "")
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [isMulti, setIsMulti] = useState<boolean>(!!props.isMulti)
    const [users, setUsers]= useState<IUser[]>([])
    useEffect(() => {
        setName( props.user?.display_name || "" )
    },[props.user])
    const onChange = (_name:string) => {
        setName(_name)
        setIsLoad(true)
        actions( 
            GET_SEARCH_USERS_ACTION, 
            { 
                search:_name, 
                role: props.role,
                landId: isMulti ? "-1" : props.landId 
            } )
            .then((resp: IUser[]) => {
                setUsers(resp) 
                setIsOpen(!!resp.length) 
                setIsLoad(false) 
            })
    }
    const selectUser = (user:IUser) => {
        props.onSelect(user)
        setUsers( [] )
        setName( "" )
        setIsOpen( false )
    }
    const onFromAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setIsMulti( evt.target.checked )
    }
    return <div className='w-100' style={{minWidth: props.minWidth || 0}}>
        <div className="d-flex align-items-center">
            <FieldInput
                type={SCALAR_TYPES.STRING}
                value={name}
                onChange={onChange}
                editable
                placeholder={__("Start write user name")}
                notClear={props.notClear}
            />
            {
                isLoad && <LoaderMinimal />
            }
        </div>
        {
            !props.onlyThisLands && 
            <div>
                <label className="_check_">
                    <input type="checkbox" onChange={onFromAll} />
                    {__("From all festivals")}
                </label>
            </div>
        }
        
        <Collapse isOpen={isOpen}>
            <ul className={`list-group list-group-flush container overflow-y-auto h_200 ${isLoad ? "opacity_5" : ""}`}>
            {
                users.map(user => {
                    return <li 
                        className='list-group-item pointer hover-straght p-1 d-flex align-items-center' 
                        key={user.id}
                        onClick={ () => selectUser( user ) }
                    >
                        <div className="user-ava" style={{backgroundImage:`url(${user.avatar})`, borderRadius:0}}></div>
                       { user.display_name}     
                    </li>
                })
            }
            </ul>
        </Collapse>
    </div>
}
export default UserSearchEngine