import { Role } from "@/libs/interfaces/layouts"
import { IProject } from "../interfaces"

export interface ITeamMember {
    id: keyof IProject
    name: string
    role: Role | Role[]
}

export const team = (): ITeamMember[] => {
    const memberRoles :Role[] = [
        "SuperAdmin",
        "administrator",
        "subscriber",
        "contributor",
        "Tutor",
        "Project_author",
        "Project_member",
        "Track_moderator",
        "School_guardian"
    ]
    return [
        {
            id: "tutor",
            name: "Tutor",
            role: [ "Tutor" ],
        },
        {
            id: "leader",
            name: "Project_author",
            role: [ "Project_author" ],
        },
        {
            id: "member0",
            name: "",
            role: memberRoles,
        },
        {
            id: "member1",
            name: "",
            role: memberRoles
        },
        {
            id: "member2",
            name: "",
            role: memberRoles
        },
        {
            id: "member3",
            name: "",
            role: memberRoles
        },
        {
            id: "member5",
            name: "",
            role: memberRoles
        },
        {
            id: "member7",
            name: "",
            role: memberRoles
        },
        {
            id: "member8",
            name: "",
            role: memberRoles
        },
        {
            id: "member9",
            name: "",
            role: memberRoles
        },
    ]
}